
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeekDatePicker from "@/components/general/WeekDatePicker.vue";
import DateRangePicker from "@/components/general/DateRangePicker.vue";

@Component({
    components: {WeekDatePicker, DateRangePicker}
})
export default class ReportElement extends Vue {
    private dates:[] =[];
    private get dateRangeText () {
        return this.dates.join(' ~ ')
    }
    private disabled: boolean=false;
    @Prop()
    private linkText!: string | null;
    @Prop()
    private route!: string | null;
    @Prop() useSmallIcon!: boolean;
    @Prop({required: true}) title!: string;
    @Prop({required: true}) description!: string;
    @Prop({required: true}) icon!: string;
    @Prop({required: true}) reportNr!: any;
    @Prop({required: true}) reportUrl!: string;
    @Prop({default: true}) showPeriod!: boolean;
    @Prop({default: false}) weekSelect!: boolean;
    @Prop({default: false}) dateRangeSelect!: boolean;
    private period: number = 0;
    private periodItems: { id: number, text: string }[] = [{id: 0, text: 'Bitte wählen'}, {
        id: 1,
        text: 'heute'
    }, {id: 2, text: 'aktuelle Woche'}, {id: 3, text: 'letzte 14 Tage'}, {id: 4, text: 'aktueller Monat'}];

    private async showReport() {
        if (this.period > 0 || !this.showPeriod) {
            await this.$emit('showReport', {
                reportUrl: this.reportUrl,
                reportNr: this.reportNr,
                period: this.period,
                showPeriod: this.showPeriod
            });
            this.period = 0;
        }
    }

    private async weekChanged(week: { week: number, year: number }): Promise<void> {
        await this.$emit('showWeekReport', {
            reportUrl: this.reportUrl,
            reportNr: this.reportNr,
            week: week,
            showPeriod: this.showPeriod
        });
        this.period = 0;
    }
    async mounted(){
        const config = JSON.parse(localStorage.getItem('Auth/userConfig')|| '');

       if(!this.route) {
           this.disabled = config.permissions.REPORTS === 0;
       }
    }

    private async dateRangeChanged(args: {start:Date, end:Date}){
        await this.$emit('showRangeReport', {
            reportUrl: this.reportUrl,
            reportNr: this.reportNr,
            start: args.start,
            end: args.end
        });
        this.period = 0;
    }
}
