import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {PosManagerRepository} from "@/modules/posManager/adapter/PosManagerRepository";
import {PosManagerPort} from "@/modules/posManager/PosManagerPort";
import {PosManagerRestRepository} from "@/modules/posManager/adapter/rest/PosManagerRestRepository";


export class PosManagerPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private orderManagerRepository: PosManagerRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createPosManagerPort(auth: AuthenticationProvider): PosManagerPort {
        const factory = new PosManagerPortFactory(auth);
        return new PosManagerPort({
            orderManagerRepository: factory.getPosManagerRepository(),
        });
    }

    private getPosManagerRepository(): PosManagerRepository {
        if (this.orderManagerRepository === null) {
            this.orderManagerRepository = new PosManagerRestRepository(this.authenticationProvider);
        }
        return this.orderManagerRepository;
    }
}
