import { render, staticRenderFns } from "./TransferButton.vue?vue&type=template&id=860f2062&scoped=true&"
import script from "./TransferButton.vue?vue&type=script&lang=ts&"
export * from "./TransferButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "860f2062",
  null
  
)

export default component.exports