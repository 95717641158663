
import {Component, Prop, Vue} from "vue-property-decorator";


@Component({components: {}})
export default class DeviceConfig extends Vue {
    @Prop({default: true})
    private showCancel!: boolean;
    private valid: boolean = true;

    private deviceConfig: any = {};

    mounted() {
        this.deviceConfig = this.$store.getters.deviceConfig;
        if (!this.deviceConfig) {
            this.deviceConfig = { useExternalSanner: false, useDatePicker: false };
        }

    }

    private cancel(): void {
        this.$emit('cancel');
    }

    private save(): void {
        try {
            this.$store.commit('SET_DEVICE_CONFIG', this.deviceConfig);
            this.$emit('save', this.deviceConfig);

        } catch (e: any) {
            console.error(e);
        }
    }
}