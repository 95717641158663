
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TransferButton from "@/components/general/TransferButton.vue";
import {globalEventBus} from "@/main";
import {ScalesRestRepository} from "@/modules/scales/adapter/rest/ScalesRestRepository";
import GridForm from "@/components/general/GridForm.vue";
import UploadButton from "@/components/general/UploadButton.vue";

@Component({
    components: {UploadButton, GridForm, TransferButton}
})
export default class CloseDialog extends Vue {
    @Prop({required:true})
    private closeApp!:boolean;

    private logoutApp(): void {
        localStorage.setItem('Auth/userConfig', '');
        this.$store.commit("Auth/setAuthToken", '');
        this.$ports.auth.logout();
    }
}
