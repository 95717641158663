import CardInfo from "@/modules/cards/models/CardInfo";
import {CardRepository} from "@/modules/cards/adapter/CardRepository";
import {Transaction} from "@/modules/cards/models/Transaction";
import {TransactionsRange} from "@/modules/cards/models/TransactionsRange";
import {Card} from "@/modules/cards";

export class CardService {

    private readonly cardRepository: CardRepository;

    constructor(params: { cardRepository: CardRepository }) {
        this.cardRepository = params.cardRepository;
    }
    public async lockCard(card: Card): Promise<void> {
        return this.cardRepository.lockCard(card);
    }
    public async getCardInfo(cardId: string): Promise<CardInfo> {
        return this.cardRepository.getCardInfo(cardId);
    }
    public async cancelTransaction(transaction: Transaction): Promise<void> {
        return this.cardRepository.cancelTransaction(transaction);
    }

    public async transferCard(cardId: string, newCardId:string): Promise<void> {
        return this.cardRepository.transferCard(cardId,newCardId);
    }
    public async unlockCard(card: Card): Promise<void> {
        return this.cardRepository.unlockCard(card);
    }
    public async getWeekReport(reportUrl:string, reportNr:number, week:{week:number, year:number}, showPeriod:boolean): Promise<string> {
        return this.cardRepository.getWeekReport(reportUrl,reportNr,week,showPeriod);
    }
    public async getRangeReport(reportUrl:string, reportNr:number, start:string, end:string): Promise<string> {
        return this.cardRepository.getRangeReport(reportUrl,reportNr,start,end);
    }
    public async getReport(reportUrl:string, reportNr:number, period:number, showPeriod:boolean): Promise<string> {
        return this.cardRepository.getReport(reportUrl,reportNr,period,showPeriod);
    }
}
