
import { Component, Prop, Vue } from 'vue-property-decorator';
import UploadButton from "@/components/general/UploadButton.vue";
@Component({
    components: {UploadButton}
})
export default class DesktopHeader extends Vue {
    @Prop()
    private transferDialogTitle!:string;
    @Prop({default: false})
    private isTransmitting!: boolean;
    @Prop({default: 1})
    private connectionState!: number;
    @Prop({ default: '' })
    private title!: string;

    @Prop({default: false})
    private isMiniMenu!:boolean;
    @Prop({default: undefined})
    private badge!:{appAreaId:number,color:string,smallText:string,text:string};
}
