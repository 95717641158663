
import {Component, Ref, Prop, Vue, Watch} from 'vue-property-decorator';
import {
    AproconFormDialog,
    AproconTable,
    ErrorHandler,
    FieldConfig, FormConfig, FormErrorHandlerParams, FormErrorHandlerResponse,
    FormsMappingProvider,
    TableConfig, TableErrorHandlerParams, TableErrorHandlerResponse
} from '@aprocon-software/aprocon-form';
import {globalEventBus} from "@/main";
import FormDialog from "@/components/general/FormDialog.vue";
import FormMappingUtility from "@/assets/utils/FormMapperUtil";
import CustomerReports from "@/components/CustomerReports.vue";

@Component({
    components: {CustomerReports, FormDialog, AproconTable, AproconFormDialog},
})
export default class GridForm extends Vue {
    private customTabSlots: any[] = [];
    private preiseFilter: string = '';
    private preiseGridName: string = "BROWSE_KUNDENPREISE";

    private preiseContext(parent: any): string {
        return this.context;
    }

    private preiseParentId(item: any): number {
        return item.NUMMER;
    }

    public async setFilter(filter: string): Promise<void> {
        this.filter = filter;

    }

    private rechnungenFilter: string = '';
    private rechnungenGridName: string = "BROWSE_RECHNUNGEN";

    private rechnungenContext(parent: any): string {
        return this.context;
    }

    private rechnungenParentId(item: any): number {
        return item.NUMMER;
    }

    private kontakteFilter: string = '';
    private kontakteGridName: string = "BROWSE_KONTAKTE";

    private kontakteContext(parent: any): string {
        return this.context;
    }

    private kontakteParentId(item: any): number {
        return item.NUMMER;
    }

    private auftraegeFilter: string = '';
    private auftraegeGridName: string = "BROWSE_AUFTRAEGE";

    private auftraegeContext(parent: any): string {
        return this.context;
    }

    private auftraegeParentId(item: any): number {
        return item.NUMMER;
    }

    @Prop({default: undefined})
    private tableConfigs!: Map<string, any> | undefined;


    private async editItem(): Promise<void> {
        //console.log('editItem');
        this.$emit('edit');
    }

    private async deleteItem(): Promise<void> {
        //console.log('deleteItem');
        this.$emit('delete');
    }

    private async formClosed(args: { tableName: string, id: number | string | undefined, action: 'Create' | 'Update' | 'View' | 'Delete' }): Promise<void> {
        //console.log('formClosed');
        this.$emit("formClosed", args);
    }

    private overrideConfig(tableConfig: TableConfig): TableConfig {
        tableConfig.fixedHeader = true;
        tableConfig.fixedFooter = true;
        this.canCreate = tableConfig.permissions.create;

        if (tableConfig.tableName === this.gridName) {
            // tableConfig.multiselect = this.multiSelect;
            tableConfig.multiselectActions = this.multiSelectActions;
            tableConfig.actions = this.singleSelectActions;
            return tableConfig;
        }

        if (this.tableConfigs) {
            if (this.tableConfigs.has(tableConfig.tableName)) {
                const config = this.tableConfigs.get(tableConfig.tableName);
                tableConfig.multiselectActions = config.multiSelectActions;
                tableConfig.actions = config.singleSelectActions;
            }
        }
        return tableConfig;
    }

    @Prop()
    private parentRecordId!: number | undefined;
    @Prop({
        default: () => {
            return FormMappingUtility.formMappingProvider;
        }
    })
    private formMapper!: any;
    /*Props*/
    @Prop({default: true})
    private openDefaultForm!: boolean;
    @Prop({required: true, default: 'BEZEICHNUNG'})
    private itemTitleProp!: string;
    @Prop({required: true, default: 'Neuer Eintrag'})
    private newItemTitle!: string;

    @Prop({required: true})
    private gridName!: string;
    @Prop({required: false, default: false})
    private showBackButton!: boolean;
    @Prop({required: false, default: false})
    private showRefreshButton!: boolean;
    @Prop({required: false, default: false})
    private disableRefreshButton!: boolean;
    @Prop()
    private goBackCallback!: any;
    @Prop()
    private refreshCallback!: any;
    @Prop({default: 'Suchen'})
    private searchLabel!: string;
    @Prop({
        default: () => {
            return [];
        }
    })
    @Prop({default: []})
    private multiSelectActions!: any[];

    @Prop({
        default: () => {
            return [];
        }
    })
    private singleSelectActions!: any[];


    /*Refs*/
    @Ref()
    private readonly form!: AproconFormDialog;
    @Ref('table')
    private readonly table!: AproconTable<any>;


    private canCreate: boolean = false;
    private filterExpression: string | null = null;

    public clearSelection(): void {
        this.table.clearSelection();
    }

    private filter: string = '';
    private formName: string = '';
    private editRecord: any = {};
    private formAction: 'Create' | 'View' | 'Update' | 'Delete' = 'View';
    private errorHandler: ErrorHandler = {
        handleTableConfigError: this.handleTableError,
        handleTableLoadError: this.handleTableError,
        handleTableUnknownError: this.handleTableError,
        handleFormConfigError: this.handleFormError,
        handleFormLoadError: this.handleFormError,
        handleFormSaveError: this.handleFormError,
        handleFormUnknownError: this.handleFormError
    };
    /*Computed Props*/
    // private get editRecordId(): number | null {
    //     return this.editRecord?.RECORDID;
    // }
    public static formMapperDefault(): any {

        return {
            mapField(field: FieldConfig, raw: any): FieldConfig {

                if (field.fieldName === "NUMMER") {

                }
                //
                return field;
            },
            mapForm: (form: FormConfig, raw: any) => {

                return form;
            },
        };
    }

    @Prop({required: true})
    private context!: string;


    private get gridContext(): string {
        if (this.context.startsWith(`Filiale=${this.$store.getters["Auth/branchId"]};`)) {
            return this.context;
        }
        return `Filiale=${this.$store.getters["Auth/branchId"]};` + this.context;
    }

    /*Watcher*/
    @Watch('context')
    private contextChanged(): void {

    }

    /*Methods*/
    private formContext(): { [key: string]: string } {
        return {'EDIT_ETIKETTENTEXTE': this.context + ',' + this.filterExpression}
    }

    public async refreshForm(): Promise<void> {
        return this.table.reloadForm();
    }

    public async refresh(full: boolean): Promise<void> {
        return this.table.reload(full);
    }


    private cancel(): void {
        this.$emit('cancel');
    }

    private async handleFormError(params: FormErrorHandlerParams): Promise<FormErrorHandlerResponse> {
        if (params.error) {
            //console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }
        }
        return {
            closeForm: true
        }
    }

    private async handleTableError(params: TableErrorHandlerParams): Promise<TableErrorHandlerResponse> {
        if (params.error) {

            console.log(params.error);
            // logout
            if (params.error.id && params.error.id === 10003) {
                await this.$store.commit("Auth/setAuthToken", '');
                await this.$ports.auth.logout();
            }
            if (params.error.message) {
                await globalEventBus.$emit('showError', params.error.message);
            } else {
                await globalEventBus.$emit('showError', params.error);
            }

            try {
                //await this.table.form.close();
            } catch (e) {
                console.log(e);
            }
        }
        return {};
        //this.cancel();
    }

    public async addItem(): Promise<void> {
        this.formAction = 'Create';
        //console.log('addItem');
        this.$emit('add');
        await this.table.createItem();

    }


    private goBack(): void {
        if (this.goBackCallback) {
            this.goBackCallback();
        } else {
            this.$router.back();
        }
    }

    private async refreshGrid(): Promise<void> {
        if (this.refreshCallback) {
            await this.refreshCallback();
        } else {
            await this.table.reload(false);
        }
    }

    private clickRow(args: { value: any, id: any, formName: string, tableName: string }): void {
        //this.$emit('rowItemClick', args);
    }

    public async saveForm(): Promise<void> {
        await this.table.form.save();
    }

    public get formMappingProvider(): FormsMappingProvider {
        return {
            mapForm: (form: FormConfig, raw: any) => {
                if (this.customTabSlots.length === 0) {
                    this.customTabSlots = [
                        //ARTIKEL
                        {
                            id: 'tab-ARTIKEL_PREISE',
                            title: 'Preise',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Preise durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_ARTIKELPREISE',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-ARTIKEL_BERICHTE',
                            title: 'Berichte',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: '',
                            searchLabel: '',
                            newItemTitle: '',
                            gridBrowse: '',
                            type: () => import ("@/components/ProductReports.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        //ARTIKELPREISE
                        {
                            id: 'tab-ARTIKELPREISE_PREISVERLAUF',
                            title: 'Preisverlauf',
                            context: '',
                            buildContext: (item: any) => {
                                return `PREISKATEGORIE=${item.PREISKATEGORIE};KUNDE=${item.KUNDE}`;
                            },
                            parentRecordId: (item: any) => {
                                return item.ARTIKEL;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Preisverlauf durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_ARTIKELPREISE',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        }, //AUFTRAEGE
                        {
                            id: 'tab-AUFTRAEGE_POSITIONEN',
                            title: 'Positionen',
                            context: 'Belegart=Bestellung',
                            buildContext: (item: any) => {
                                return `Belegart=Bestellung`;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Positionen durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_AUFTRAGSPOSITIONEN',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [{
                                icon: "<i class='ordermanager-bill-credit-card'></i>",
                                displayName: 'Liefermengen übernehmen',
                                action: async (entries: any[], config: TableConfig) => {
                                    globalEventBus.$emit("loadingChanges", {loading: true});
                                    globalEventBus.$emit("showConfirm", {
                                        title: "Liefermengen",
                                        message: "Für die gewählten Positionen werden die Liefermengen anhand der Bestellmengen eingetragen." +
                                            "Soll diese Aktion jetzt gestartet werden?",
                                        confirmationCallback: async () => {
                                            try {

                                                const selected: { Nummer: number }[] = [];
                                                entries.forEach(
                                                    (e) => {
                                                        selected.push({Nummer: e.id});
                                                    })

                                                try {
                                                    const message = await this.$ports.orderManager.acceptOrderQuantities(selected);
                                                    globalEventBus.$emit("showInfo", {
                                                        title: "Bestellungen",
                                                        message: message
                                                    });

                                                } catch (e: any) {
                                                    globalEventBus.$emit("showError", e.message);
                                                } finally {
                                                    await this.refreshGrid();
                                                }
                                            } catch (e: any) {
                                                globalEventBus.$emit("showError", e.message);
                                            } finally {
                                                globalEventBus.$emit("loadingChanges", {loading: false});
                                            }
                                        }
                                    });
                                },
                                enabled: () => true
                            }],
                            OnEditTabTable: () => {
                                this.table.form.reload();
                                console.log('OnEditTabTable');
                            },
                            OnEdit: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-edit');
                            },
                            OnDelete: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-delete');
                            },
                            OnAdd: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-add');
                            }
                        },
                        //KUNDEN_EX
                        {
                            id: 'tab-KUNDEN_EX_KONTAKTE',
                            title: 'Kontakte',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Kontakte durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_KONTAKTE',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-KUNDEN_EX_STAMMARTIKEL',
                            title: 'Stammartikel',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Stammartikel durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_STAMMARTIKEL',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [
                                {
                                    action: async (entries: any[], config: TableConfig) => {

                                        globalEventBus.$emit("loadingChanges", {loading: true});
                                        globalEventBus.$emit("showConfirm", {
                                            title: "Bestellung",
                                            message: "Möchten Sie anhand der selektierten Artikel eine neue Bestellung anlegen?",
                                            confirmationCallback: () => this.doBestellungAusStammArtikeln(entries)
                                        });
                                    },
                                    displayName: 'Bestellung',
                                    icon: "<i class='ordermanager-order'></i>"
                                }
                            ],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-KUNDEN_EX_AUFTRAEGE',
                            title: 'Auftraege',
                            context: 'Belegart=Bestellung',
                            buildContext: (item: any) => {
                                return `Belegart=Bestellung`;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Aufträge durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_AUFTRAEGE',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-KUNDEN_EX_RECHNUNGEN',
                            title: 'Rechnungen',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Rechnungen durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_RECHNUNGEN',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-KUNDEN_EX_PREISE',
                            title: 'Preise',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Preise durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_KUNDENPREISE',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        {
                            id: 'tab-KUNDEN_EX_BERICHTE',
                            title: 'Berichte',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Berichte durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_BERICHTE',
                            type: () => import ("@/components/CustomerReports.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                            },
                            OnEdit: () => {
                            },
                            OnAdd: () => {
                            },
                            OnDelete: () => {
                            }
                        },
                        //RECHNUNGEN
                        {
                            id: 'tab-RECHNUNGEN_POSITIONEN',
                            title: 'Positionen',
                            context: '',
                            buildContext: (item: any) => {
                                return ``;
                            },
                            parentRecordId: (item: any) => {
                                return item.NUMMER;
                            },
                            titleProp: 'SUCHBEGRIFF',
                            searchLabel: 'Positionen durchsuchen',
                            newItemTitle: '',
                            gridBrowse: 'BROWSE_RECHNUNGSPOSITIONEN',
                            type: () => import("@/components/general/GridForm.vue"),
                            singleSelectActions: [],
                            multiSelectActions: [],
                            OnEditTabTable: () => {
                                this.table.form.reload();
                                console.log('OnEditTabTable');
                            },
                            OnEdit: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-edit');
                            },
                            OnDelete: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-delete');
                            },
                            OnAdd: () => {
                                this.saveForm();
                                console.log('OnEditTabTable-add');
                            }
                        }
                    ];
                }
                if (form.formName === 'EDIT_ARTIKEL') {

                    form.sheets.push({
                        id: 'ARTIKEL_PREISE',
                        title: 'Preise',
                        order: 10,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'ARTIKEL_BERICHTE',
                        title: 'Berichte',
                        order: 11,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_ARTIKELPREISE') {
                    form.sheets.push({
                        id: 'ARTIKELPREISE_PREISVERLAUF',
                        title: 'Preisverlauf',
                        order: 12,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_AUFTRAEGE') {
                    form.sheets.push({
                        id: 'AUFTRAEGE_POSITIONEN',
                        title: 'Positionen',
                        order: 13,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_KUNDEN_EX') {
                    form.sheets.push({
                        id: 'KUNDEN_EX_KONTAKTE',
                        title: 'Kontakte',
                        order: 14,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'KUNDEN_EX_STAMMARTIKEL',
                        title: 'Stammartikel',
                        order: 15,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'KUNDEN_EX_AUFTRAEGE',
                        title: 'Aufträge',
                        order: 16,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'KUNDEN_EX_RECHNUNGEN',
                        title: 'Rechnungen',
                        order: 17,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'KUNDEN_EX_PREISE',
                        title: 'Preise',
                        order: 18,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'KUNDEN_EX_BERICHTE',
                        title: 'Berichte',
                        order: 19,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_RECHNUNGEN') {
                    form.sheets.push({
                        id: 'RECHNUNGEN_POSITIONEN',
                        title: 'Positionen',
                        order: 20,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                return form;
            },
            mapField: this.mapField
        }
    }

    private mapField(field: FieldConfig, raw: any): FieldConfig {
        if (raw.TYPE === "CALENDAR") {
            console.log(raw);
            field.fieldType = () => import("@/components/CustomFormControls/DateFormField.vue");
            field.inputType = () => import("@/components/CustomFormControls/DateFormInput.vue");
            return field;
        }
        if (raw.TYPE === "CLOCK") {
            console.log(raw);
            field.fieldType = () => import("@/components/CustomFormControls/TimeFormField.vue");
            field.inputType = () => import("@/components/CustomFormControls/TimeFormInput.vue");
            return field;
        }
        return field;
    }

    private async doBestellungAusStammArtikeln(entries: any[]): Promise<void> {

        try {

            const selected: { Nummer: number }[] = [];
            entries.forEach(
                (e) => {
                    selected.push({Nummer: e.id});
                })

            try {
                const response = await this.$ports.orderManager.createOrderByArticles(selected);
                if (response && response.UserMessage) {
                    try {
                        await this.table.cancelForm();
                        globalEventBus.$emit('showConfirm', {
                            title: 'Bestellung',
                            message: response.UserMessage,
                            confirmationCallback: async () => {
                                await this.$router.replace({
                                    path: "/bestellungen",
                                    query: {filter: response.AuftragNr}
                                });
                            }
                        });
                    } catch (e: any) {
                        console.log(e);
                    }
                }
            } catch (e: any) {
                globalEventBus.$emit("showError", e.message);
            } finally {
                //await this.refreshGrid();
            }
        } catch (e: any) {
            globalEventBus.$emit("showError", e.message);
        } finally {
            globalEventBus.$emit("loadingChanges", {loading: false});
        }
    }

    public async formClose(): Promise<void> {
        try {
            console.log("formClose");
            await this.table.form.close();
        } catch (e: any) {
            console.log(e);
        }
    }
}
