
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from "moment-mini";

@Component({components: {}})
export default class DateRangePicker extends Vue {

    @Prop({default: false}) disabled!: boolean;
    private dates: string[] = [];
    private formattedDate: string = '';
    private dateMenu: boolean = false;

    @Watch('dates', { deep: true})
    private dateChanged(): void {
       if(this.dates && this.dates.length === 2) {
           this.dateMenu = !this.dateMenu;
           let newDates = JSON.parse(JSON.stringify(this.dates));
           let dateFormat='';

          const datesSort = newDates.sort(DateRangePicker.dateComparison);
           newDates.forEach((d:any) => {
               dateFormat += moment(d).format("DD.MM.YYYY") + " - ";
           });
           this.formattedDate = dateFormat.substr(0,dateFormat.length - 2);
           this.$emit("dateRangeChanged",{ start:datesSort[0], end: datesSort[1], title: this.formattedDate});
       }
    }

    private static dateComparison(a:any, b:any) {
        const date1 = moment(a);
        const date2 = moment(b);

        return  date1.toDate().valueOf() - date2.toDate().valueOf();
    }

    private get firstDayOfWeek(): number {
        return 1;
    }

    constructor() {
        super();
    }

    mounted() {

    }

    private get maxDate(): string {
        return moment(new Date()).format("YYYY-MM-DD");
    }
}
