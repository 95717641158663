import { render, staticRenderFns } from "./ReportElement.vue?vue&type=template&id=660c7719&scoped=true&"
import script from "./ReportElement.vue?vue&type=script&lang=ts&"
export * from "./ReportElement.vue?vue&type=script&lang=ts&"
import style0 from "./ReportElement.vue?vue&type=style&index=0&id=660c7719&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660c7719",
  null
  
)

export default component.exports