
import {Component, Prop, Vue} from 'vue-property-decorator';
import moment, {Moment} from "moment-mini";

@Component({components: {}})
export default class WeekDatePicker extends Vue {

    @Prop({default: false}) disabled!: boolean;
    private weekRange: string[] =['',''];
    private formattedDate: string = '';
    private dateMenu: boolean = false;
    private week : {week:number, year:number} ={ week: 0, year: 0};
    // private getDateOfWeek(w:number, y:number) {
    //     const d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    //     return new Date(y, 0, d);
    // }
    private getFormattedDate(date: Date, separator: string = "-"): string {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();
        return yyyy + separator + mm + separator + dd;
    }
    private getCurrentWeekDates(now: Date = new Date()): string[] {
        const d = now.getDay(); //get the current day
        let weekStart = new Date(now.valueOf() - (d <= 0 ? 7 - this.firstDayOfWeek : d - this.firstDayOfWeek) * 86400000); //rewind to start day
        if (this.firstDayOfWeek === 0) {
            weekStart = new Date(now.valueOf() - (d < 0 ? 7 - this.firstDayOfWeek : d - this.firstDayOfWeek) * 86400000); //rewind to start day
        }
        const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get last day
        return [this.getFormattedDate(weekStart),this.getFormattedDate(weekEnd)];
    }
    private get firstDayOfWeek(): number {
        return 1;
    }
    openOnChange(date:Date | undefined) {

        if(date) {
            const dateRange = this.getCurrentWeekDates(date);
            this.setFormattedDate(dateRange[0]);
            this.weekRange = dateRange;
        } else {
            const dateRange = this.getCurrentWeekDates(new Date());
            this.setFormattedDate('');
            this.weekRange = dateRange;
        }
    }
    private weekRangeChanged(newValue: string[]) {
        this.dateMenu = false;
        if (this.weekRange[newValue.length - 1]) {
            this.openOnChange(new Date(this.weekRange[newValue.length - 1]));
            const date = moment(this.weekRange[newValue.length - 1]);
            const selectedWeek = {week:this.calculateWeekNumber(date), year: date.isoWeekYear()};
           // if(this.week.week !== selectedWeek.week || this.week.year !== selectedWeek.year) {
                this.$emit("weekChanged",{start: this.weekRange[0], end: this.weekRange[1], title: this.formattedDate});
                this.week = selectedWeek.week;
            //}
            // this.$emit("input", this.weekRange[newValue.length - 1]);
        } else {
            this.openOnChange(undefined);
        }
    }

    private calculateWeekNumber (date:Moment) : {week:number, year:number} {
        return ((date.week()) - this.firstDayOfWeek) === 0 ? {week: 52, year: date.isoWeekYear() -1} : {week: (date.week()) - this.firstDayOfWeek, year: date.isoWeekYear()};
    }
    constructor() {
        super();
    }

    mounted() {
        this.openOnChange(undefined);
    }

    //TODO: get calendar week - localisation
    private setFormattedDate(startDate: string) {
        if(startDate) {
            const date = moment(startDate);
            const week = this.calculateWeekNumber(date);
            this.formattedDate = `KW ${week.week} ${week.year}`;
        } else {
            this.formattedDate = '';
        }
    }

    private get maxDate(): string {
        return this.getFormattedDate(new Date());
    }

    private clearDateValue() {
        this.weekRange = this.getCurrentWeekDates();
        this.$emit("weekChanged",{week:0, year: 0, title:''});
        //this.$emit("input", this.weekRange[0]);
    }
}
