import { Store } from 'vuex';
import type { StoreRepository } from '@/modules/auth';

export class AuthModuleAdapter implements StoreRepository {

    private readonly store: Store<any>;

    constructor(store: Store<any>) {
        this.store = store;
    }

    public getServiceUrl(): string | undefined {
        return this.store.getters['Auth/serviceUrl'];
    }

    public getToken(): string | undefined {
        return this.store.getters['Auth/authToken'];
    }

    public getUserId(): string | undefined {
        return this.store.getters['Auth/userId'];
    }

    public setServiceUrl(serviceUrl: string): void {
        this.store.commit('Auth/setServiceUrl', serviceUrl);
    }

    public setToken(token: string): void {
        this.store.commit('Auth/setAuthToken', token);
    }

    public setUserId(userId: string): void {
        this.store.commit('Auth/setUserId', userId);
    }
    public getBranchId(): string | undefined {
        return this.store.getters["Auth/branchId"];
    }
    public setBranchId(branchId: string): void {
        this.store.commit('Auth/setBranchId', branchId);
    }
}
