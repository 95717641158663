
import { Component, Vue } from 'vue-property-decorator';
import DeviceConfigComp from "@/components/DeviceConfig.vue";

@Component({
    components: { DeviceConfigComp },
})
export default class Notify extends Vue {

    private notify(): void {
        console.log(Notification?.permission);
        if (Notification?.permission === "granted") {
            // If the user agreed to get notified
            // Let's try to send ten notifications
            new Notification(`Hi!tester`, {body: `hello ${Math.random()}`, tag: "soManyNotification"});
            setTimeout(this.notify,2500);

        } else {
            // If the user hasn't told if they want to be notified or not
            // Note: because of Chrome, we are not sure the permission property
            // is set, therefore it's unsafe to check for the "default" value.
            Notification.requestPermission().then((status) => {
                // If the user said okay
                if (status === "granted") {

                    const n = new Notification(`Hi! tester`, { body: "hello",
                        tag: "soManyNotification",
                    });

                } else {
                    // Otherwise, we can fallback to a regular modal alert
                    console.log(status);
                }
            });
        }
    }



}
