
import {Component, Vue} from 'vue-property-decorator';
import moment from "moment-mini";


@Component({components: {}})
export default class MonthDatePicker extends Vue {
    private month: string = '';
    private dateMenu: boolean = false;

    // private getStartEndDates(date: Date): { start: Date, end: Date } {
    //     const endDate = date.AddMonths(1).AddDays(-1);
    //     return {start: date, end: endDate};
    // }

    private get monthDisplay(): string {
        if(this.month) {
        return moment(this.month).format("MMMM YYYY");
        }
        return '';
    }
    private getFormattedDate(date: Date, separator: string = "-"): string {
        if (date) {
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            return yyyy + separator + mm + separator + dd;
        } else {
            return '';
        }
    }

    private get firstDayOfWeek(): number {
        return 1;
    }

    openOnChange(date: Date | undefined) {
        // if (date) {
        //     this.month = date.getMonth().toString();
        // }
    }

    private monthChanged() {
        this.dateMenu = false;
        const date = new Date(this.month);
        //console.log(date);
        const start = date;
        const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
        this.$emit("monthChanged", {start: start, end: end, title: this.monthDisplay});
    }

    constructor() {
        super();
    }

    mounted() {
        this.openOnChange(new Date());
    }

    //TODO: get calendar week - localisation
    private setFormattedDate(startDate: string) {

    }

    private get maxDate(): string {
        return this.getFormattedDate(new Date());
    }

    private clearDateValue() {
       // this.$emit("monthChanged", {start: this.picker, end: this.picker});
        //this.$emit("input", this.weekRange[0]);
    }
}
