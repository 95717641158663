
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ScalesRestRepository} from "@/modules/scales/adapter/rest/ScalesRestRepository";
import {globalEventBus} from "@/main";


@Component({components: {}})
export default class UploadButton extends Vue {
    @Prop({default: 'Übertragung läuft'})
    private title!: string;
    @Prop({default: ''})
    private buttonClass!: string;
    @Prop({default: false})
    private light!: boolean;
    @Prop({default: false})
    private isTransmitting!: boolean;
    @Prop({default: 1})
    private connectionState!: number;

    private dialog: boolean = false;

    private get loadingColor(): string {
        switch (this.connectionState) {
            case 3:
                return "error";
            case 0:
                return "error";
            default:
                return "info";
        }
    }

    private get isError(): boolean {
        return this.connectionState === 3 || this.connectionState === 0;
    }
    private get dialogTitle(): string {
        if (this.title) {
            return this.title;
        }
        return "Übertragung"
    }

    private get dialogInfoText(): string {
        // nicht verbunden=0,
        // inaktiv=1,
        // aktiv=2,
        // Connect-Dienst nicht bereit=3
        if (this.isTransmitting) {
            const value = this.connectionState;
            switch (value) {
                case 3:
                    return "Der APRO.CON Connect-Dienst ist nicht bereit.";
                case 2:
                    return "Eine Übertragung läuft...";
                case 1:
                    return "Eine Übertragung läuft...";
                case 0:
                    return "Der APRO.CON Connect-Dienst ist nicht verbunden."
                case -1:
                    return "Eine Übertragung wurde gestartet."
            }
        }
        return "";
    }

    @Watch('isTransmitting')
    private async isTransmittingChanged(): Promise<void> {
        if (this.dialog && !this.isTransmitting) {
            this.dialog = false;
        }
    }
}
