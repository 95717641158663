import { LoginRepository, LoginResponse } from '../LoginRepository';

export class LoginRepositoryImpl implements LoginRepository {

    private readonly loginUrl: string;

    constructor(params: { loginUrl: string }) {
        this.loginUrl = params.loginUrl;
    }

    public logout(params: { appId: string; serviceUrl?: string }): void {
        let targetUrl = `${this.loginUrl}/logout?app=${params.appId}&redirect=${window.location.href}`;
        if (params.serviceUrl) {
            targetUrl = `${targetUrl}&service=${decodeURIComponent(params.serviceUrl)}`;
        }
        window.location.href = targetUrl;
    }

    public login(params: { appId: string; serviceUrl?: string }): LoginResponse|undefined {
        const response = this.readResponse();
        if (!response) {
            console.info('No auth parameter found in url, redirecting to login');
            this.redirectToLogin(params);
        }
        return response;
    }

    private readResponse(): LoginResponse|undefined {
        try {
            const query: string = window.location.search;
            const startIndex: number = query.startsWith('?auth=') ? 0 : query.indexOf('&auth=');
            if (startIndex === -1) {
                return undefined;
            }
            let endIndex: number|undefined = query.indexOf('&', startIndex + 1);
            if (endIndex === -1) {
                endIndex = undefined;
            }
            // + 6 to remove &auth= from beginning
            const auth: string = query.substring(startIndex + 6, endIndex);
            const response: any = JSON.parse(atob(decodeURIComponent(auth)));

            // remove auth param from url
            let path: string = window.location.pathname;
            if (startIndex > 0) {
                path += query.substring(0, startIndex);
            }
            if (endIndex !== undefined) {
                path += query.substring(endIndex);
            }
            path += window.location.hash;
            window.history.pushState(null, document.title, path);

            return {
                service: response.service,
                token: response.sid,
                userId: response.userId,
                branchId: response.branchId
            };
        } catch (e) {
            console.error('Failed to read login response from URL');
            return undefined;
        }
    }

    private redirectToLogin(params: { appId: string; serviceUrl?: string }): void {
        let targetUrl = `${this.loginUrl}/login?app=${params.appId}&redirect=${window.location.href}`;
        if (params.serviceUrl) {
            targetUrl = `${targetUrl}&service=${decodeURIComponent(params.serviceUrl)}`;
        }
        window.location.href = targetUrl;
    }
}
