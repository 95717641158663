
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import PdfViewerDialog from "@/components/general/PdfViewerDialog.vue";
import {globalEventBus} from "@/main";
import PosManagerReportElement from "@/components/PosManagerReportElement.vue";
import ReportElement from "@/components/ReportElement.vue";


@Component({
    components: {PosManagerReportElement, PdfViewerDialog, ReportElement}
})
export default class CustomerReports extends Vue {
    private items:any[]=[];
    private orderManagerReports:any[]=[];
    private loading: boolean = false;
    @Prop({default: -1})
    private customerId!: number;

    @Ref()
    private pdfViewer!: PdfViewerDialog;

    private async showReport(args:{start:Date, end:Date, reportNr:number, title: string}) : Promise<void> {
        this.loading = true;
        try{
            const url = await this.$ports.orderManager.getProductReport(args);
            if (url.endsWith(".pdf")) {
                // if(this.$vuetify.breakpoint.mdAndDown) {
                await this.downloadFile(url);
                // } else {
                //     if (this.pdfViewer) {
                //         await this.pdfViewer.show(url, args?.value?.value?.DATUM);
                //     }
                // }
            } else {
                globalEventBus.$emit("showError", "Der Bericht konnte nicht geladen werden");
            }
            // console.log(url);
        }catch(e) {
            globalEventBus.$emit("showError", e.message);
        }finally {
            this.loading = false;
        }

        // this.$ports.card.getReport(item.reportUrl, item.reportNr, item.period, item.showPeriod).then((url) => {
        //     // window.open(url, '_blank');
        //     if (this.pdfViewer) {
        //         this.pdfViewer.show(url, 'Bericht');
        //         console.log(url);
        //     }
        // }).catch((e) => {
        //     globalEventBus.$emit("showError", e.message);
        // }).finally(() => {
        //     this.loading = false;
        // });
    }

    private async downloadFile(file: string): Promise<void> {
        // const fileRes = await fetch(file);
        // const fileBlob = await fileRes.blob();
        // const url = window.URL.createObjectURL(fileBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = file;
        a.target = '_blank';

        // the filename you want
        //a.download = file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.URL.revokeObjectURL(url);
    }
    async mounted(){
        try {

            this.orderManagerReports = []//await this.$ports.orderManager.getProductReports(this.productId);
            //   console.log(this.orderManagerReports);
            // this.orderManagerReports.forEach((item)=>{
            //     this.items.push(
            //         {
            //             title: item.HEADER,
            //             description:item.DESCRIPTION,
            //             reportNr: item.REPORTNR,
            //             icon: item.ICON_SVG,
            //             period: item.PERIOD
            //         });
            // });
        } catch(e:any) {
            globalEventBus.$emit("showError", e.message);
        }

        // this.posManagerReports.push({"REPORTNR" : 3,
        //     "HEADER" : "Test - Month",
        //     "DESCRIPTION" : "Auswertung der Warengruppenumsätze im wählbaren Zeitraum",
        //     "ICON_SVG" : "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" \"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">\n<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"32px\" height=\"32px\" viewBox=\"0 0 32 32\" enable-background=\"new 0 0 32 32\" xml:space=\"preserve\">\n  <g id=\"icon\">\n    <path d=\"M30,30H4v-4H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2V8h2V6H2V4h2V0h26V30zM6,28h22V2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6V28z\" fill=\"#3C3C3C\"/>\n    <polygon points=\"11.618,24.685 10.077,23.41 14.523,18.039 18.714,20.952 21.509,17.325 19.335,17.703 18.993,15.733 24.746,14.733 25.385,20.438 23.397,20.661 23.152,18.47 19.136,23.68 14.891,20.731\" fill=\"#3C3C3C\" stroke-linejoin=\"miter\"/>\n    <rect x=\"11\" y=\"4\" width=\"14\" height=\"2\" fill=\"#3C3C3C\"/>\n    <rect x=\"13\" y=\"8\" width=\"10\" height=\"2\" fill=\"#3C3C3C\"/>\n  <\/g>\n<\/svg>\n",
        //     "PERIOD" : 3});
        // this.posManagerReports.push({"REPORTNR" : 5,
        //     "HEADER" : "Test - Day",
        //     "DESCRIPTION" : "Auswertung der Warengruppenumsätze im wählbaren Zeitraum",
        //     "ICON_SVG" : "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" \"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">\n<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"32px\" height=\"32px\" viewBox=\"0 0 32 32\" enable-background=\"new 0 0 32 32\" xml:space=\"preserve\">\n  <g id=\"icon\">\n    <path d=\"M30,30H4v-4H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2V8h2V6H2V4h2V0h26V30zM6,28h22V2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6V28z\" fill=\"#3C3C3C\"/>\n    <polygon points=\"11.618,24.685 10.077,23.41 14.523,18.039 18.714,20.952 21.509,17.325 19.335,17.703 18.993,15.733 24.746,14.733 25.385,20.438 23.397,20.661 23.152,18.47 19.136,23.68 14.891,20.731\" fill=\"#3C3C3C\" stroke-linejoin=\"miter\"/>\n    <rect x=\"11\" y=\"4\" width=\"14\" height=\"2\" fill=\"#3C3C3C\"/>\n    <rect x=\"13\" y=\"8\" width=\"10\" height=\"2\" fill=\"#3C3C3C\"/>\n  <\/g>\n<\/svg>\n",
        //     "PERIOD" : 1});

    }
}
