import {CardActionsRepository} from "@/modules/cardActions/adapter/CardActionsRepository";
import {MasterDataRepository} from "@/modules/masterData/adapter/MasterDataRepository";

export class MasterDataPort implements MasterDataRepository{
    private readonly masterDataRepository: MasterDataRepository;

    constructor(params: { masterDataRepository: MasterDataRepository }) {
        this.masterDataRepository = params.masterDataRepository;
    }
    public getGridList():any[] {
        return this.masterDataRepository.getGridList();
    }

}
