
import {CardService} from "@/modules/cards/service/CardService";
import CardInfo from "@/modules/cards/models/CardInfo";
import {Transaction} from "@/modules/cards/models/Transaction";
import {TransactionsRange} from "@/modules/cards/models/TransactionsRange";
import {Card} from "@/modules/cards/models";

export class CardPort {
    private readonly cardsService: CardService;

    constructor(params: { cardsService: CardService }) {
        this.cardsService = params.cardsService;
    }
    public async lockCard(card: Card): Promise<void> {
        return this.cardsService.lockCard(card);
    }
    public async unlockCard(card: Card): Promise<void> {
        return this.cardsService.unlockCard(card);
    }
    public async transferCard(cardId: string, newCardId:string): Promise<void> {
        return this.cardsService.transferCard(cardId,newCardId);
    }
    public async getCardInfo(cardId: string): Promise<CardInfo> {
        return this.cardsService.getCardInfo(cardId);
    }
    public async cancelTransaction(transaction: Transaction): Promise<void> {
        return this.cardsService.cancelTransaction(transaction);
    }

    public async getReport(reportUrl:string, reportNr:number, period:number, showPeriod:boolean): Promise<string> {
        return this.cardsService.getReport(reportUrl,reportNr,period,showPeriod);
    }

    public async getWeekReport(reportUrl:string, reportNr:number, week:{week:number, year:number}, showPeriod:boolean): Promise<string> {
        return this.cardsService.getWeekReport(reportUrl,reportNr,week,showPeriod);
    }

    public async getRangeReport(reportUrl:string, reportNr:number, start:string, end: string): Promise<string> {
        return this.cardsService.getRangeReport(reportUrl,reportNr,start,end);
    }
}
