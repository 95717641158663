import {FormConfig, FormsMappingProvider} from "@aprocon-software/aprocon-form";

export default class FormMappingUtility {

    public static get formMappingProvider(): FormsMappingProvider {
        return {
            mapForm: (form: FormConfig, raw: any) => {

                if (form.formName === 'EDIT_ARTIKEL') {

                    form.sheets.push({
                        id: 'preiseArtikel',
                        title: 'Preise',
                        order: 10,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'artikelberichte',
                        title: 'Berichte',
                        order: 11,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_ARTIKELPREISE') {
                    form.sheets.push({
                        id: 'preisverlauf',
                        title: 'Preisverlauf',
                        order: 12,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_AUFTRAEGE') {
                    form.sheets.push({
                        id: 'positionenAuftrag',
                        title: 'Positionen',
                        order: 13,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_KUNDEN_EX') {
                    form.sheets.push({
                        id: 'kontakte',
                        title: 'Kontakte',
                        order: 14,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'stammartikel',
                        title: 'Stammartikel',
                        order: 15,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'auftraege',
                        title: 'Aufträge',
                        order: 16,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'rechnungen',
                        title: 'Rechnungen',
                        order: 17,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'preise',
                        title: 'Preise',
                        order: 18,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                    form.sheets.push({
                        id: 'kundenberichte',
                        title: 'Berichte',
                        order: 19,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                if (form.formName === 'EDIT_RECHNUNGEN') {
                    form.sheets.push({
                        id: 'positionen',
                        title: 'Positionen',
                        order: 20,
                        hidden: {expression: '', triggeredByFields: [], value: false},
                        disabled: {expression: '', triggeredByFields: [], value: false},
                    });
                }
                return form;
            },
        }
    }
}
