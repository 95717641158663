import {LicensingService} from "@/modules/licensing/services/LicensingService";
import {License} from "@/modules/licensing/models";

export class LicensingPort {
    private readonly licensingService: LicensingService;

    constructor(params: { licensingService: LicensingService }) {
        this.licensingService = params.licensingService;
    }
    public async getLicenseInfo(): Promise<License> {
        return this.licensingService.getLicenseInfo();
    }
}
