import {AuthRepository} from "@/modules/auth/adapter/AuthRepository";
import {LicensingRepository} from "@/modules/licensing/adapter/LicensingRepository";
import {License} from "@/modules/licensing";


export class LicensingService {
    private readonly licensingRepository: LicensingRepository;
    constructor(params: { licensingRepository: LicensingRepository }) {
        this.licensingRepository = params.licensingRepository;
    }
    public async getLicenseInfo(): Promise<License> {
        return this.licensingRepository.getLicenseInfo();
    }
}
