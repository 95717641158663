
import {Component, Prop, Vue} from 'vue-property-decorator';
import UploadButton from "@/components/general/UploadButton.vue";
import {globalEventBus} from "@/main";
import {ScalesRestRepository} from "@/modules/scales/adapter/rest/ScalesRestRepository";

@Component({
    components: {UploadButton},
})
export default class TransferButton extends Vue {

    @Prop({default: false})
    private disabled!:boolean;
    private uploadIcon:string = require(`@/assets/icons/input-output-light.svg`);
    private isTransmitting:boolean=false;

    private async transferItem():Promise<void> {
        this.$emit("transferItem",{ isTransmitting: this.isTransmitting, title: "Waagen und Kassen"});
    }

}
