
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import axios from "axios";

  @Component
  export default class PdfViewerDialog extends Vue {
    private url: string = '';
    private open:boolean = false;
    private title:string = '';

    public async show(url:string, title:string):Promise<void> {

        this.title = title;
        const response = await axios.get(url, {responseType: 'blob'});
        const blob = new Blob([response.data], {type: 'application/pdf'})
        //const link = document.createElement('a');
        this.url = URL.createObjectURL(blob);
        this.open = true;
    }

    private close() : void {
        try{
            URL.revokeObjectURL(this.url);
        }catch (e) {
            //console.log(e);
        }

        this.open = false;
    }
  }
