import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import {LoginRepositoryImpl} from "@/modules/auth/adapter/impl/LoginRepositoryImpl";
import Notify from "@/views/Notify.vue";
const Logs = (resolve: (...modules: any[]) => void) => require(['../views/logs/Logs.vue'], resolve);
const Masterdata = (resolve: (...modules: any[]) => void) => require(['../views/masterdata/Masterdata.vue'], resolve);
const MasterdataGrid = (resolve: (...modules: any[]) => void) => require(['../views/masterdata/MasterdataGrid.vue'], resolve);
const About = (resolve: (...modules: any[]) => void) => require(['../views/About.vue'], resolve);
const Reports = (resolve: (...modules: any[]) => void) => require(['../views/reports/Reports.vue'], resolve);
const Products = (resolve: (...modules: any[]) => void) => require(['../views/products/Products.vue'], resolve);
const Orders = (resolve: (...modules: any[]) => void) => require(['../views/orders/Orders.vue'], resolve);
const DeliveryNotes = (resolve: (...modules: any[]) => void) => require(['../views/deliveryNotes/DeliveryNotes.vue'], resolve);
const Bills = (resolve: (...modules: any[]) => void) => require(['../views/bills/Bills.vue'], resolve);
const Customers = (resolve: (...modules: any[]) => void) => require(['../views/customers/Customers.vue'], resolve);
const TopsFlops = (resolve: (...modules: any[]) => void) => require(['../views/sales/TopFlop.vue'], resolve);

const GridView = (resolve: (...modules: any[]) => void) => require(['../views/Grid.vue'], resolve);

const StartApp = (resolve: (...modules: any[]) => void) => require(['../views/StartApp.vue'], resolve);
const DeviceConfig = (resolve: (...modules: any[]) => void) => require(['../views/DeviceConfig.vue'], resolve);

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '/bestellungen',
        component: Orders,
        meta: {
            loginRequired: true,
            pageTitle: 'Bestellungen',
            pageIcon: 'ordermanager-order',
            pageBadge: {
                areaId: "BESTELLUNG"
            }
        }
    },
    {
        path: '/lieferscheine',
        component: DeliveryNotes,
        meta: {
            loginRequired: true,
            pageTitle: 'Lieferscheine',
            pageIcon: 'ordermanager-document-delivery-receipt',
            pageBadge: {
                areaId: "LIEFERSCHEIN"
            }
        }
    },
    {
        path: '/rechnungen',
        component: Bills,
        meta: {
            loginRequired: true,
            pageTitle: 'Rechnungen',
            pageIcon: 'ordermanager-document-invoice',
        }
    },
    {
        path: '/berichte',
        component: Reports,
        meta: {
            loginRequired: true,
            pageTitle: 'Berichte',
            pageIcon: 'ordermanager-presentation-business',
        }
    },
    {
        path: '/artikel',
        component: Products,
        meta: {
            loginRequired: true,
            pageTitle: 'Artikel',
            pageIcon: 'ordermanager-item',
        }
    },
    {
        path: '/kunden',
        component: Customers,
        meta: {
            loginRequired: true,
            pageTitle: 'Kunden',
            pageIcon: 'ordermanager-people-customers',
        }
    },
    {
        path: '/stammdaten',
        component: Masterdata,
        children:[
            {
                name: 'stammdaten',
                path: '',
                redirect: '0',
            },
            {
                path: ':masterDataId',
                component: MasterdataGrid,
                meta: {
                    loginRequired: true,
                    pageTitle: 'Stammdaten',
                    pageIcon: 'ordermanager-database',
                },
            }],
        meta: {
            loginRequired: true,
            pageTitle: 'Stammdaten',
            pageIcon: 'ordermanager-database',
        },
    },
];
/*{
    path: '/waageundkasse',
        component: Scales,
    children:[
    {
        name: 'waageundkasse',
        path: '',
        redirect: '0',
    },
    {
        path: ':masterDataId',
        component: ScalesGrid,
        meta: {
            loginRequired: true,
            pageTitle: 'Waage & Kasse',
            pageIcon: 'fa-cash-register',
        },
    }],
    meta: {
    loginRequired: true,
        pageTitle: 'Waage & Kasse',
        pageIcon: 'fa-cash-register',
},
}*/
const otherRoutes: Array<RouteConfig> = [
    {
        path: '*',
        redirect: '/bestellungen'
    },
    {
        path: '/',
        redirect: '/bestellungen'
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            pageTitle: 'Version',
            pageIcon: 'fa-question',
        },
    },
    {
        path: '/umsaetze/topsundflops',
        component: TopsFlops,
        meta: {
            loginRequired: true,
            pageTitle: 'Umsätze / Tops & Flops',
            pageIcon: 'fa-file',
        },
    },
    {
        path: '/logs',
        component: Logs,
        children:[
            {
                name: 'logs',
                path: '',
                redirect: '0'
            },
            {
                path: ':logCategory',
                component: Logs,
                meta: {
                    loginRequired: true,
                    pageTitle: 'Eventlog',
                    pageIcon: 'ordermanager-database',
                },
            }],
        meta: {
            loginRequired: true,
            pageTitle: 'Eventlog',
            pageIcon: 'ordermanager-database',
        },
    },
    {
        path: '/gridview',
        component: GridView,
    },{
        path: '/notify',
        component: Notify,
    },{
        path: '/deviceConfig',
        component: DeviceConfig,
    },{
        path: '/start',
        name: 'start',
        component: StartApp,
        meta: {
            loginRequired: false,
            pageTitle: 'Start',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes: routes.concat(otherRoutes),
});


export default router;
