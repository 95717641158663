
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeekDatePicker from "@/components/general/WeekDatePicker.vue";
import DateRangePicker from "@/components/general/DateRangePicker.vue";
import DayDatePicker from "@/components/general/DayDatePicker.vue";
import MonthDatePicker from "@/components/general/MonthDatePicker.vue";

@Component({
    components: {WeekDatePicker, DateRangePicker, DayDatePicker, MonthDatePicker}
})
export default class ReportElement extends Vue {
    private get showChoices(): boolean {
        return this.choices && this.choices.length>0
    }
    private lastArgs: any = null;
    private disabled: boolean = false;
    @Prop()
    private linkText!: string | null;
    @Prop()
    private route!: string | null;
    @Prop() useSmallIcon!: boolean;
    @Prop({required: true}) title!: string;
    @Prop({required: true}) description!: string;
    @Prop({required: true}) icon!: string;
    @Prop({required: true}) reportNr!: any;
    @Prop({default: 0}) period!: number;

    private choice: number=0;

    @Prop({default: ()=>[]}) choices!: { ID:number, Value:string}[];

    private get downloaded(): boolean {
        return this.lastArgs !== null;
    }

    private reloadReport() {
        if (this.lastArgs) {
            this.$emit('showReport', this.lastArgs);
        }
    }

    private async showChoiceReport(): Promise<void> {
        if (this.period === 0) {
            this.lastArgs = {
                choice: this.choice,
                reportNr: this.reportNr,
                start: undefined,
                end: undefined,
                title: this.title //`${this.title} ${args.title.trim()}`
            };
            this.$emit('showReport', this.lastArgs);
        }
    }
    private showReport(args: { start: Date, end: Date, title: string }) {


        if(!this.showChoices) {
            if (this.period > 0 && args) {
                this.lastArgs = {
                    reportNr: this.reportNr,
                    start: args.start,
                    end: args.end,
                    title: `${this.title} ${args.title.trim()}`
                };
                this.$emit('showReport', this.lastArgs);
            }
        }
    }

    async mounted() {
        const config = JSON.parse(localStorage.getItem('Auth/userConfig') || '');
        if (!this.route) {
            this.disabled = config.permissions.REPORTS === 0;
        }
    }
}
