
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {routes} from '@/router';
import LogoutDialog from '@/components/LogoutDialog.vue';
import {RouteConfig} from "vue-router";
import {globalEventBus} from "@/main";
import {ref, Ref} from "vue";

@Component({components: {LogoutDialog}})
export default class Menu extends Vue {
    private loading:boolean = true;
    @Prop()
    private userConfig!:any;
    private badges:{APPID:string, APPAREA:string,COLOR:string,BADGETEXT:string,EXTENSIVETEXT:string}[] | undefined=undefined;
    private badgesTimer: NodeJS.Timer | undefined;

    @Prop({default: false})
    private showLogo!: boolean;
    private userRoutes:RouteConfig[] = [];


    private logoutApp() {
        if (this.showLogOut) {
            localStorage.setItem('Auth/userConfig', '');
            this.$store.commit("Auth/setAuthToken", '');
            this.resetCaller();
            this.$ports.auth.logout();
        } else {
            this.resetCaller();
        }
    }


    async mounted() {



        globalEventBus.$on("refreshBadges", this.checkBadges);
await this.setRoutes();
        await this.refreshBadges();

    }
    @Watch('userConfig')
    private async setRoutes() : Promise<void> {
        try {
            this.loading = true;
            this.userRoutes=[];
            routes.forEach((r) => {
                if (r.path == "/artikel") {
                    if (this.userConfig.permissions.COMMON.ARTIKEL === 1) {
                        this.userRoutes.push(r);
                    }
                } else if (r.path === "/kunden") {
                    if (this.userConfig.permissions.COMMON.KUNDEN === 1) {
                        this.userRoutes.push(r);
                    }
                } else if (r.path === "/berichte") {
                    if (this.userConfig.permissions.COMMON.REPORTS === 1) {
                        this.userRoutes.push(r);
                    }
                } else if (r.path === "/stammdaten") {

                    if (this.userConfig.permissions.COMMON.STAMMDATEN === 1) {
                        this.userRoutes.push(r);
                    }
                } else {
                    this.userRoutes.push(r);
                }
            });
        } catch (e: any) {

        } finally {
            this.loading = false;
        }
    }
    private async refreshBadges(): Promise<void> {
        this.$forceUpdate();
        await this.checkBadges();
    }
    private async checkBadges(): Promise<void> {
        if (this.badgesTimer) {
            window.clearTimeout(this.badgesTimer);
        }
        const badges = await this.$ports.orderManager.getBadges("APRO.ORDER");
        this.badges = badges;
        this.$forceUpdate();
        this.badgesTimer = setTimeout(this.checkBadges, 1000 * 10);

    }
    private get showLogOut(): boolean {
        return !this.$store.getters["Base/caller"];
    }

    private resetCaller(): void {
        this.$store.commit("Base/setCaller", "");
    }

    private getCallerUrl() {
        let caller = this.$store.getters['Base/caller'];
        if (!caller) {
            return "#";
        }
        const bid = this.$ports.auth.getBranchId();
        if(bid  && Number.parseInt(bid) > 0) {
            return caller + decodeURIComponent("?bid=" + this.$ports.auth.getBranchId());
        }
        return caller;
    }

    public getBadge(areaId:string): any {
       // console.log(areaId);
        if(this.badges && this.badges.length>0) {
            return this.badges.find((b)=>b.APPAREA === areaId);
        }
        return null;
    }
    unmounted(){
        globalEventBus.$off("refreshBadges");
        if (this.badgesTimer) {
            clearTimeout(this.badgesTimer);
        }
    }
}
