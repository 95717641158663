import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {CardActionsRepository} from "@/modules/cardActions/adapter/CardActionsRepository";
import {CardActionsPort} from "@/modules/cardActions/CardActionsPort";
import {CardActionsRestRepository} from "@/modules/cardActions/adapter/rest/CardActionsRestRepository";

export class CardActionsPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private cardActionsRepository: CardActionsRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createCardsActionsPort(auth: AuthenticationProvider): CardActionsPort {
        const factory = new CardActionsPortFactory(auth);
        return new CardActionsPort({
            cardsActionsRepository: factory.getCardActionsRepository(),
        });
    }

    private getCardActionsRepository(): CardActionsRepository {
        if (this.cardActionsRepository === null) {
            this.cardActionsRepository = new CardActionsRestRepository(this.authenticationProvider);
        }
        return this.cardActionsRepository;
    }
}
