import { AuthService, CloudflareService } from '@aprocon-software/aprocon-auth-util';
import type { AuthRepository } from '../AuthRepository';
import type { StoreRepository } from '../StoreRepository';
import axios from "axios";

export class AuthRepositoryImpl implements AuthRepository {
    private readonly appId:string='';
    private readonly authClient: AuthService;
    private readonly ipClient: CloudflareService;
    private readonly store: StoreRepository;

    constructor(params: {
        appId: string,
        store: StoreRepository,
    }) {
        this.appId = params.appId;
        this.store = params.store;
        this.ipClient = new CloudflareService();
        this.authClient = new AuthService({
            serviceUrl: () => this.getServiceUrl(),
            sid: () => this.getAuthToken(),
            ip: () => this.ipClient.getIp(),
            appId: params.appId,
        });
    }

    public async testCredentials(params: { serviceUrl?: string; authToken?: string; userId?: string, branchId?: string }): Promise<boolean> {
        if (!params.serviceUrl || !params.userId || !params.authToken) {
            return false;
        }
        this.store.setServiceUrl(params.serviceUrl);
        this.store.setToken(params.authToken);
        this.store.setUserId(params.userId);
        this.store.setBranchId(params.branchId || '');
        return await this.isLoggedIn();
    }

    public async isLoggedIn(): Promise<boolean> {
        return await this.authClient.testCredentials();
    }

    public getServiceUrl(): string {
        return this.store.getServiceUrl() || '';
    }

    public getAuthToken(): string {
        return this.store.getToken() || '';
    }

    public getUserId(): string {
        return this.store.getUserId() || '';
    }

    public getBranchId(): string {
        return this.store.getBranchId() || '';
    }

    public logout(): void {
        this.store.setToken(undefined);
    }

    public async getSessionInfo(sid:string): Promise<any> {
        return this.authClient.getSessionInfo(sid);
    }
}
