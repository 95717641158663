import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import moment from 'moment-mini';
import ports from '@/plugins/ports';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import axios from "axios";


axios.defaults.timeout = Number.parseInt(process.env.VUE_APP_AXIOS_TIMEOUT || '5000');


Vue.config.productionTip = false;

moment.locale('de', {
    months:'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
    weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
});

export const axiosController = new AbortController();
export const globalEventBus = new Vue();

Vue.use(ports, store);
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
