
import {Component, Prop, Vue} from 'vue-property-decorator';
import moment, {Moment} from "moment-mini";

@Component({components: {}})
export default class DayDatePicker extends Vue {

    @Prop({default: false}) disabled!: boolean;
    private date: string = '';
    private formattedDate: string = '';
    private dateMenu: boolean = false;

    // private getDateOfWeek(w:number, y:number) {
    //     const d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    //     return new Date(y, 0, d);
    // }

    private get firstDayOfWeek(): number {
        return 1;
    }
    openOnChange(date:Date | undefined) {

        if(date) {

            this.setFormattedDate(this.date);

        } else {

            this.setFormattedDate('');

        }
    }
    private getFormattedDate(date: Date, separator: string = "-"): string {
        if (date) {
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            return yyyy + separator + mm + separator + dd;
        } else {
            return '';
        }
    }
    constructor() {
        super();
    }

    mounted() {
        this.openOnChange(undefined);
    }

    //TODO: get calendar week - localisation
    private setFormattedDate(startDate: string) {
        if(startDate) {
            const date = moment(startDate);

        } else {

        }
    }
    private dayChanged() {
        this.dateMenu = false;
        const date = new Date(this.date);

        this.$emit("dayChanged", {start: date, end: date, title: this.formattedDate});
    }
    private get maxDate(): string {
        return this.getFormattedDate(new Date());
    }

    private clearDateValue() {

        this.dayChanged();
        //this.$emit("dayChanged",{date: this.getFormattedDate(new Date())});
        //this.$emit("input", this.weekRange[0]);
    }
}
