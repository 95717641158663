import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {CardActionsRepository} from "@/modules/cardActions/adapter/CardActionsRepository";

export class CardActionsRestRepository implements CardActionsRepository {
    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    public printTransactions(ids:number[]):void {
        console.log('printTransactions - restrepository');
        console.log(ids);
    }

    public cancelTransactions(ids: number[]): void {
        console.log('cancelTransactions - restrepository');
        console.log(ids);
    }
}
