
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import AproconFooter from '@aprocon-software/aprocon-footer';
import AproconHeader from '@aprocon-software/aprocon-header';
import Menu from '@/components/Menu.vue';
import DesktopHeader from '@/components/DesktopHeader.vue';
import {globalEventBus} from "@/main";
import PersistentDialog from "@aprocon-software/aprocon-main-components";
import CloseDialog from "@/components/CloseDialog.vue";
import UploadButton from "@/components/general/UploadButton.vue";
@Component({
    components: {
        CloseDialog,
        DesktopHeader,
        AproconHeader,
        AproconFooter,
        Menu,
        PersistentDialog,
        UploadButton
    },
})
export default class App extends Vue {
    @Watch("$route")
    private async routeChanged():Promise<void> {
        if(!this.$store.getters["Auth/serviceUrl"]){
            if(this.$route.meta?.loginRequired) {
                await this.$store.dispatch("Auth/login");
            }else {
                await this.$router.replace("/");
            }
        }else {
            if (!this.initialized && this.$route.meta?.loginRequired) {
                await this.init();
            }
        }
    }

    private smallTabs: boolean = false;
    private closeApp: boolean = false;
    private userConfig: any;
    private transferDialogTitle: string = '';
    //private transferTimer: NodeJS.Timer | undefined;
    private transferTimer: NodeJS.Timer | undefined;
    private timeoutTimer: NodeJS.Timer | undefined;
    private openInfoMenu: boolean = false;
    @Ref('header')
    private readonly header?: any;
    private initialized = false;
    private branchInfo: string = '';
    private branches: { Nummer: number, Name: string }[] = [];
    private selectedBranch: number = 0;
    @Ref()
    private messageDialog!: any;
    private isTransmitting: boolean = false;
    private connectionState: number = 1;

    private userChangedBranch() : void {

    }
    private toggleBranchCombobox() {
        const branchesCbx: any = this.$refs.branchesCbx;
        if (branchesCbx.isMenuActive) {
            (this.$refs.branchesCbx as any).isMenuActive = false;
        } else {
            (this.$refs.branchesCbx as any).isMenuActive = true;
            branchesCbx.focus();
        }
    }
    private async init(): Promise<void> {
        try {
            globalEventBus.$on("apiIsDown", this.apiIsDown);
            globalEventBus.$on("showError", this.showError);
            globalEventBus.$on("showInfo", this.showInfo);
            globalEventBus.$on("showConfirm", this.showConfirm);
            globalEventBus.$on("transferChanged", this.transferChanged);
            //globalEventBus.$on("transferChanged", this.transferChanged);
            await this.$store.dispatch('Auth/login');
            this.keepAlive();

            // get isa from query
            const query = {...this.$route.query};
            if (query.caller) {
                const caller = query.caller;
                await this.$store.commit('Base/setCaller', caller);
                delete query.caller;
                await this.$router.push(query);
            }
            this.userConfig = await this.$ports.auth.getUserConfig(true);
            await this.$nextTick(async () => {
                this.branches = await this.$ports.auth.getBranches(this.userConfig.permissions.GESAMTSICHT);
                let selectedBranch = Number.parseInt(this.$store.getters["Auth/branchId"]);
                const item = this.branches.find((branch) => branch.Nummer === selectedBranch);
                if (!item && this.branches.length > 0) {
                    selectedBranch = this.branches[0].Nummer;
                    this.$store.commit("Auth/setBranchId", selectedBranch);
                }
                this.selectedBranch = selectedBranch;
            });
             this.closeApp = this.userConfig?.permissions?.ZUGRIFF !== 1;
            await this.checkTransmitting();

        } catch (e) {
            this.showError(e.message);
        } finally {
            this.initialized = true;
        }
    }

    private keepAlive(): void {
        this.$store.dispatch('Auth/keepAlive')
            .then((success: boolean) => {
                if (success) {
                    // test credentials every 60 seconds
                    setTimeout(this.keepAlive, 1000 * 60);
                } else {
                    this.logoutApp();
                }
            })
            .catch(() => {
                this.$store.dispatch('Auth/login');
            });
    }

    private async checkTransmitting(): Promise<void> {
        //console.log("checkTransmitting");
        this.connectionState = await this.$ports.orderManager.getConnectionStatus();
        this.isTransmitting = this.connectionState !== 1;
        this.transferTimer = setTimeout(this.checkTransmitting, 1000 * 5);
    }

    private menuItemClicked(): void {
        if (this.header) {
            this.header.close();
        }
    }

    private get title(): string {
        if (this.$route.meta?.pageTitle) {

            if(this.$route.params.logCategory) {
                const logCategory= this.$route.params.logCategory;
                let categeory = 'Lieferscheine';
                if(logCategory === "1") {
                    categeory = 'Rechnungen';
                }
                return `${categeory} / ${this.$route?.meta?.pageTitle}`;
            }


            if (this.$route.params.masterDataId) {
                const items = this.$ports.masterData.getGridList();
                const item = items.find((item) => item.id === Number.parseInt(this.$route.params.masterDataId));
                if (item) {
                    return `${this.$route?.meta?.pageTitle} / ${item.name}`;
                }
                return `${this.$route?.meta?.pageTitle}`;
            } else if (this.$route.params.salesId) {
                const items = this.$ports.orderManager.getSalesGridList();
                const item = items.find((item) => item.id === Number.parseInt(this.$route.params.salesId));
                if (item) {
                    return `${this.$route?.meta?.pageTitle} / ${item.name}`;
                }
                return `${this.$route?.meta?.pageTitle}`;
            } else {
                if(this.$route.meta?.pageBadge) {
                    // pageBadge
                    return `${this.$route?.meta?.pageTitle}`;
                }
                return `${this.$route?.meta?.pageTitle}`;
            }
        }
        return '';
    }

    private get selectedUser(): string {
        return this.$store.getters["Auth/userId"];
    }

    @Watch('selectedUser')
    private async selectedUserChanged(): Promise<void> {
        await this.init();
    }
    private async branchChangedByUser():Promise<void> {
        try{

            await this.$ports.auth.switchBranch(this.selectedBranch);
            //this.$store.commit("Auth/setBranchId", this.selectedBranch.toString());
        }catch(e:any){
            console.log(e);
        }finally {
            this.$store.commit("Auth/setBranchId", this.selectedBranch.toString());
        }
    }
    @Watch('selectedBranch')
    private async selectedBranchChanged(): Promise<void> {

        const item = this.branches.find((branch) => branch.Nummer === this.selectedBranch);
        if (item) {
            this.branchInfo = item.Name;

        } else {
            this.branchInfo = '';
        }
        //this.connectionState = await this.$ports.orderManager.getConnectionStatus();
        // this.isTransmitting = this.connectionState !== 1;
    }

    private apiIsDown(): void {
        this.messageDialog.showError("API", "Der Webservice ist nicht erreichbar");
    }

    private showError(message: string): void {
        this.messageDialog.showError("", message);
    }
    private showInfo(args:{title:string, message: string}): void {
        this.messageDialog.showInfo(args.title, args.message);
    }
    private showConfirm(entry: { title: string, message: string, confirmationCallback: () => {} }): void {
        this.messageDialog.showConfirmation(entry.title, entry.message, entry.confirmationCallback);
    }

    private async transferChanged(args: { isTransmitting: boolean, title: string }) {
        // console.log('transferChanged-App');
        this.isTransmitting = args.isTransmitting;
        this.transferDialogTitle = args.title;
    }

    async mounted() {

    }

    unmounted() {
        globalEventBus.$off("apiIsDown", this.apiIsDown);
        globalEventBus.$off("showError", this.showError);
        globalEventBus.$off("showConfirm", this.showConfirm);
       // globalEventBus.$off("transferChanged", this.transferChanged);
        // if (this.transferTimer) {
        //     clearTimeout(this.transferTimer);
        // }

        if (this.timeoutTimer) {
            clearTimeout(this.timeoutTimer);
        }
    }

    @Watch('branchId')
    private branchIdChanged() {
        this.$router.go(0);
    }

    private get branchId(): string {
        return this.$store.getters["Auth/branchId"];
    }

    private logoutApp(): void {
        localStorage.setItem('Auth/userConfig', '');
        this.$store.commit("Auth/setAuthToken", '');
        this.$ports.auth.logout();
    }
}
