import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";

import axios from "axios";
import {ScalesRepository} from "@/modules/scales/adapter/ScalesRepository";

export class ScalesRestRepository implements ScalesRepository {
    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    public getGridList(): any[] {
        const sid = this.auth.getAuthToken();
        const user = this.auth.getUserId();
        if (sid) {
            // const response = await axios.post(`${this.auth.getServiceUrl()}/Stammdaten/Stammdaten_GetGridList`, {
            //     SID: sid,
            //     UserId: user
            // });
            // if (response.data.Stammdaten_GetGridList_response?.ServiceErrors) {
            //     throw new Error(response.data.Stammdaten_GetGridList_response.ServiceErrors[0].ERRORRECOMMENDATION);
            // } else {
            const tabs = [];
            //const apiTabs = response.data.Stammdaten_GetGridList_response;
            // | Sortiment | Verkäufer | Rabatte | Lieferscheinkunden

            tabs.push({
                id: 0,
                route: `/waageundkasse/0`,
                name: 'Sortiment',
                icon: 'ordermanager-types',
                gridName: 'BROWSE_ABTEILUNGEN',
                formName: '',
            });
            tabs.push({
                id: 1,
                route: `/waageundkasse/1`,
                name: 'Verkäufer',
                icon: 'ordermanager-people-employees',
                gridName: 'BROWSE_FILIALVERKAUFSPERSONAL',
                formName: '',
            });
            tabs.push({
                id: 2,
                route: `/waageundkasse/2`,
                name: 'Rabatte',
                icon: 'ordermanager-coupon',
                gridName: 'BROWSE_FILIALWAAGENRABATTE',
                formName: '',
            });
            tabs.push({
                id: 3,
                route: `/waageundkasse/3`,
                name: 'Lieferscheinkunden',
                icon: 'ordermanager-people-customers',
                gridName: 'BROWSE_FILIALWAAGENKUNDEN',
                formName: '',
            });
            tabs.push({
                id: 4,
                route: `/waageundkasse/4`,
                name: 'Standort',
                icon: 'ordermanager-location-circle',
                gridName: '',
                formName: 'EDIT_FILIALEN',
            });
            tabs.push({
                id: 5,
                route: `/waageundkasse/5`,
                name: 'Übertragen',
                icon: 'ordermanager-cash-register-input-output',
                gridName: '',
                formName: '',
            });

            return tabs;
            //     }
            // } else {
            //     return Promise.reject();
            // }
        }
        return [];
    }

}
