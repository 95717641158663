import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {OrderManagerRepository} from "@/modules/orderManager/adapter/OrderManagerRepository";
import {OrderManagerPort} from "@/modules/orderManager/OrderManagerPort";
import {OrderManagerRestRepository} from "@/modules/orderManager/adapter/rest/OrderManagerRestRepository";


export class OrderManagerPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private orderManagerRepository: OrderManagerRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createPosManagerPort(auth: AuthenticationProvider): OrderManagerPort {
        const factory = new OrderManagerPortFactory(auth);
        return new OrderManagerPort({
            orderManagerRepository: factory.getPosManagerRepository(),
        });
    }

    private getPosManagerRepository(): OrderManagerRepository {
        if (this.orderManagerRepository === null) {
            this.orderManagerRepository = new OrderManagerRestRepository(this.authenticationProvider);
        }
        return this.orderManagerRepository;
    }
}
