
import {ScalesRepository} from "@/modules/scales/adapter/ScalesRepository";

export class ScalesPort implements ScalesRepository{
    private readonly scalesRepository: ScalesRepository;

    constructor(params: { scalesRepository: ScalesRepository }) {
        this.scalesRepository = params.scalesRepository;
    }
    public getGridList():any[] {
        return this.scalesRepository.getGridList();
    }

}
