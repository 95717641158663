
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LogoutDialog extends Vue {
    openDialog: boolean = false;

    logout(): void {
        localStorage.setItem('Auth/userConfig','');
        this.$store.commit("Auth/setAuthToken",'');
        this.$store.commit("Base/setCaller", "");
        this.$ports.auth.logout();
    }
}
