import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import AuthModule from '@/store/auth/AuthModule';
import BaseModule from '@/store/base/BaseModule';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<any>({
    modules: ['Auth','Base'],
    storage: localStorage,
    key: process.env.VUE_APP_LOCALSTORAGE_KEY,
});

const store = new Vuex.Store({
    plugins: [vuexLocal.plugin],
    modules: {
        Auth: AuthModule,
        Base: BaseModule
    },
});

export default store;
